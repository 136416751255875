
import Image from 'next/image'
import Link from 'next/link';


const HeaderLogo = () => {
    return (
        <Link  href='/'>
            <Image priority={false} src={ `/images/${process.env.brand.acronym}/logo-main.png` } alt={'logo-main'} width={150}  height={50} quality={75}/>
        </Link>
    )
}

export default HeaderLogo